'use client'

import React from 'react'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import MicrosoftIcon from '@mui/icons-material/Microsoft'
import { useSearchParams } from 'next/navigation'
import RiseWallpaper from '../../../public/rise-wallpaper.jpg'
import { signIn } from '@/components/wrappers/Auth'
import RiseLogoWithText from '@/components/icon/RiseLogoWithText'

const SignIn = (): JSX.Element => {
    const searchParams = useSearchParams()
    return (
        <Grid container sx={{ height: '100vh' }}>
            <Grid
                item
                xs={0}
                md={7}
                sx={{
                    backgroundImage: `url(${RiseWallpaper.src})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: { xs: 'center', md: 'left center', xl: 'center' },
                }}
            />
            <Grid
                item
                xs={12}
                md={5}
                display="flex"
                alignItems="center"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundImage: { xs: `url(${RiseWallpaper.src})`, md: 'none' },
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: 'rgb(250, 250, 250)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <Paper
                    variant="outlined"
                    sx={{
                        mx: { xs: 1, sm: 'auto' },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: { xs: '100%', sm: 400 },
                        height: 180,
                        p: 2,
                        position: 'relative',
                    }}
                >
                    <RiseLogoWithText fontSize={60} />
                    <Box sx={{ mt: 2, width: '100%' }}>
                        <Button
                            type="button"
                            onClick={() => signIn(searchParams.get('callbackUrl'))}
                            fullWidth
                            variant="contained"
                            sx={{ mt: 1, mb: 1 }}
                            startIcon={<MicrosoftIcon />}
                            size="large"
                        >
                            Log In
                        </Button>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default SignIn
